function UserDataDisplay(props){
    return (
    <>
        <p>user: <br></br>
        id: {props.state.id} <br></br>
        name: {props.state.name}</p>
        option: {props.state.option}
    </>
    )
}

export default UserDataDisplay;