import { useState, useEffect } from 'react';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UserDataDisplay from './user-data-display';

const RemoteControl = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [inputSocketUrl, SetInputSocketUrl] = useState("https://touchdesigner-lights.peacock-owl.ts.net:9980");
  const [remoteUrl, setRemoteUrl] = useState("https://touchdesigner-lights.peacock-owl.ts.net:9980");

  useEffect(() => {
      if(window.localStorage.getItem('socketUrl'))
      {
        setRemoteUrl(window.localStorage.getItem('socketUrl'));
      }
  });

  const saveHttpUrl = function(){
    window.localStorage.setItem('socketUrl', inputSocketUrl);
    setRemoteUrl(inputSocketUrl);
  }

  const playHttp = async function(){
    await postData(inputSocketUrl, { type: "sendoption", option: location.state.option });
    postData(inputSocketUrl, { type: "play" });
  }

  const stopHttp = function(){
    postData(inputSocketUrl, { type: "stop" });
  }

  const sendOptionHttp = function(){
    postData(inputSocketUrl, { type: "sendoption", option: location.state.option });
  }

  async function postData(url = '', data = {}) {
    console.log(url);
    // Default options are marked with *
    const response = await fetch(url, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'no-cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      headers: {
        'Content-Type': 'application/json'
        // 'Content-Type': 'application/x-www-form-urlencoded',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
      body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return response; // parses JSON response into native JavaScript objects
  }

  const backButtonPressed = function(){
    navigate("/");
  }

  return (
    <div className="content">
      <div className="main-content">
      <h2>TouchDesigner URL: {remoteUrl === "" ? "(not set)" : remoteUrl}</h2>
      set URL:<input type="text" value={inputSocketUrl} onChange={(e) => {SetInputSocketUrl(e.target.value);}} />
      <button onClick={saveHttpUrl}>Set URL</button>
      <br></br>
      <UserDataDisplay state={location.state} />
      <button onClick = {sendOptionHttp}>Set Option</button> <br></br>
      <button onClick = {playHttp}>Play</button>
      <br></br>
      <button onClick = {stopHttp}>Stop</button>
      <br></br>
      <button onClick = {backButtonPressed}>Back to scanner</button><br></br>
      </div>
    </div>
  );
}

export default RemoteControl;
