import UserQRScanner from "./qr-scanner";
import RemoteControl from "./remote-control";
import { Routes, Route } from "react-router-dom";

const App = () =>{
    return (
        <Routes>
                <Route path="/" element={<UserQRScanner />} />
                <Route path="/remote-control" element={<RemoteControl />} />
        </Routes>
    )
}

export default App;