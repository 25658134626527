import { Html5Qrcode } from "html5-qrcode"
import { useNavigate} from "react-router-dom";
import './qr-scanner.css'

const UserQRScanner = () =>{

  const navigate = useNavigate();

  function dummyScanSuccess(){
    const dummyJson = `{"id":"3je81s", "name":"마이클", "option":2}`
    var data = JSON.parse(dummyJson);
    navigate('../remote-control', {state: data});
  }

  function startScanning(){
    // This method will trigger user permissions
    Html5Qrcode.getCameras().then(devices => {
      if (devices && devices.length) {
        const scanner = new Html5Qrcode("reader");
        scanner.start(
          { facingMode: "environment" }, 
          {
            fps: 10,    // Optional, frame per seconds for qr code scanning
            qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
          },
          (decodedText, decodedResult) => { //onsuccess
            var data = JSON.parse(decodedText);
            navigate('../remote-control', {state:data});
            scanner.stop();
          }, (error) => { //onerror: warn to console and keep scanning.
            console.warn(`Code scan error = ${error}`);
          })
        .catch((err) => {
          alert(`start failed ${err}`);
        });
      }
    }).catch(err => {
      alert(`error getting camera ${err}`);
    })
  }
    
  return(
      <div className="content">
        <div className="main-content">
          <div id="reader" width="50%"></div>
          <button onClick = {startScanning}>scan</button><br></br>
          <button onClick = {dummyScanSuccess}>test</button><br></br>
        </div>
      </div>
  )
}

export default UserQRScanner;